
import { strings } from '@/strings'
import { locator, ParsedPhoneNumber } from '@/utils/locator'
import { router } from '@/router'
import PhoneInput from '@/components/ui/phone-input.vue'

export default {
  name: 'HeroContent',
  components: { PhoneInput },
  data () {
    return {
      strings: strings
    }
  },
  methods: {
    submit (parsed_number: ParsedPhoneNumber) {
      // init locator and save parsed number
      locator.init(parsed_number)

      router.push(`/sign-up?c=${encodeURIComponent(parsed_number.dial)}&n=${parsed_number.number}`)
      // show sign-up modal and set the parsed number in properties
      // modal.show(SignUpModal, parsed_number)
    }
  },
  mounted () {

  }
}
